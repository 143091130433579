const theme = {
    breakpoints: ['576px', '768px', '992px', '1200px', '1400px', '1600px'],
    colors: {
        text: '#000',
        white: '#ffffff',
        light: '#F8F9FA',
        foreground: '#fff',
        background: '#000034',
        primary: '#07c',
        secondary: '#0000D4',
        accent: '#0000FF',
        tertiary: '#009ee3',
        muted: '#f6f6f6',
    },
     fonts: {
        body: 'degular, sans-serif',
        heading: 'degular, sans-serif',
        monospace: 'Menlo, monospace',
    },
    fontWeights: {
        body: 100,
        heading: 100,
        light: 100,
        regular: 200,
        medium: 500,
        bold: 600,
    },
    lineHeights: {
        body: 1.5,
        medium: 1.25,
        heading: 1.125,
        tight: 1.005,
    },
     fontSizes: [11,12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 60, 110],
        heding: {
            color:'white',
        },
    links: {
        contact: {
            fontSize:  [2,3,3,3,3],
            lineHeight:'body',
            fontWeight: 'medium',
            textDecoration: 'none',
            color: 'background',
        },
    },
    text: {
        blogTitle: {
            fontSize: [8],
            lineHeight:'tight',
            fontWeight: 'light',
            letterSpacing:['-1px']
        },
        blogSubtitle: {
            fontSize: [6],
            lineHeight:'tight',
            fontWeight: 'light',
            letterSpacing: ['-1px'],
            color: 'tertiary',
        },
        display: {
            fontSize: [7,8,9],
            lineHeight:['tight','heading'],
            fontWeight: 'light',
            letterSpacing:['-1px']
        },
        display1: {
            fontSize: [7,8],
            lineHeight:'tight',
            fontWeight: 'light',
            letterSpacing:['-1px']
        },
        title: {
            fontSize: [5,7,8],
            lineHeight:'heading',
            fontWeight: 'light',
            letterSpacing:['-0.1px','-1px']
        },
        subhead: {
            fontSize: [6,7],
            lineHeight:'medium',
            fontWeight: 'light',
            letterSpacing: ['-0.5px'],
            color:'tertiary',
        },
        subtitle: {
            fontSize: [6,6],
            lineHeight:'medium',
            fontWeight: 'light',
            letterSpacing:['-0.5px']
        },
        sidebar: {
            fontSize: [5,5],
            lineHeight:'medium',
            fontWeight: 'light',
            letterSpacing:['-0.5px']
        },
        openingParagraph: {
            fontSize: [5,5],
            lineHeight:'medium',
            fontWeight: 'light',
        },
        notice: {
            fontSize: [2,3,4,4,4],
            lineHeight:'body',
            fontWeight: 'light',
            letterSpacing:['-0.5px']
        },
        quote: {
            fontSize: [2,3,4,4,4],
            lineHeight:'body',
            fontWeight: 'light',
            letterSpacing:['-0.5px']
        },
        body: {
            fontSize: [5,4,4],
            lineHeight:[1.3,1.5],
            fontWeight: 'light',
            'ul': {
                'li': {
                    paddingTop: [3,4],
                },
                'li:first-of-type ': {
                    paddingTop: 0,
                }
            },
        },
        nav: {
            fontSize: [4,2,2,2,2],
            lineHeight:'body',
            fontWeight: 'regular',
            color: 'light',
            letterSpacing: '0.5px',
            '&:hover': {
                color: '#fff',
            }
        },
        navMobile: {
            fontSize: [7,6,4,4,2],
            lineHeight:'body',
            fontWeight: 'regular',
            color: 'light',
            letterSpacing: '0.5px',
            '&:hover': {
                color: 'background',
            }
        },
        navLinks: {
            fontSize: [3,3,4,4,4],
            lineHeight: 'body',
            color: 'light',
            fontWeight: 'medium',
            textDecoration: 'none',
            '&:hover': {
                color: 'background',
            }
        },

  
        medium: {
            fontSize: '9vw', 
        },
        heading: {
            
        }
    },
     buttons: {
        primary: {
            color: 'background',
            bg: 'primary',
            '&:hover': {
                bg: 'text',
            }
        },
        secondary: {
            color: 'background',
            bg: 'secondary',
         },
        login: {
            color: 'foreground',
            bg: 'accent',
            borderRadius: ['20px','30px'],
            padding: ['10px 20px','10px 30px'],
            fontSize: 'inherit',
            '&:hover': {
                 background: 'secondary',
             },
         },
         accent: {
            color: 'foreground',
            bg: 'accent',
            borderRadius: ['20px','30px'],
            padding: ['10px 20px','10px 30px'],
            fontWeight: 'medium',
            fontSize: [2, 3],
            '&:hover': {
                 background: 'secondary',
             },
         },
         accentFluid: {
            color: 'foreground',
            bg: 'accent',
            borderRadius: ['20px','30px'],
            padding: ['10px 20px','10px 30px'],
            fontWeight: 'medium',
             fontSize: [2, 3],
            width:['100%','100%',"100%"]
        },
         card: {
            color: 'accent',
            bg: 'foreground',
            fontWeight: 'medium',
            paddingTop:'10px',
            fontSize: [2, 3],
             padding: 0,
             textDecoration: 'none'
            
        }
    },
    structure: {
        subTitleWrapper: {
            width:['100%','75%']
        },
        scrollSnap: {
        },
        scrollItem: {
            scrollSnapAlign: 'start',
            scrollSnapStop: 'always',
        }, 
        scrollItemProximity: {
            scrollSnapAlign: 'start',
            scrollSnapStop: 'normal',
        },
        textStage: {
            width: '100%',
            height: '100%',
            alignItems:'end'
        },
        offsetRow: {
            background:'light',
            h2:{
                color: 'background',
            },
            h3:{
                color: 'background',
            }
        },
       landingPage: {
           'h3': {
                paddingTop:[3,3],
                fontSize: [6,6],
                lineHeight:'medium',
                fontWeight: 'light',
                letterSpacing:['-0.5px']
           },
           'p': {
               paddingBottom:[3,3],
           }
        },
        swiperCard: {
            'a': {
                display: 'block',
                cursor:'pointer',
                'svg': {
                    transition: 'margin-left 0.3s',
                },
                '&:hover': {
                    cursor:'pointer',
                    'svg': {
                        marginLeft:'30px'
                    }
                }
            }  
        
        },
        swiperCardTop: {
            minHeight: ['100px', '150px'],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            textDecoration:'none',
             'h5': {
                 fontSize: [3, 4, 5],
                 fontWeight: 'light',
            },
              'p': {
                 fontWeight: '500',
            }
        },
        swiper: {
            cardtop: {
                'a':{
                        textDecoration:'none'
                    },
                '.icon_readmore': {
                    display: 'flex',
                    
                    'svg': {
                        width: '20px',
                        justifyContent: 'between',
                        alignItems: 'center',
                        
                    },
                    
                },
                 
            }
        }

    }
}

export default theme